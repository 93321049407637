@import "~antd/dist/antd.css";

:root {
  --height-of-menubar: 43px;
  --card-border-color: #d2d2d2;
  --gray-border: 1px solid var(--card-border-color);
  --color-blue-link: #40a9ff;
  --ast-header-color: #eeeeee;
  --ast-border-color: #d2d2d2;
  --ast-select-color: #e6f7ff;
  --ast-highlight-color: #f3f3f3;
  --ast-border: 1px solid var(--ast-border-color);
}

.home__tree-header {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.home__tree-header:hover {
  color: var(--color-blue-link) !important;
  transition: 0.3s;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #636262 !important;
}

.split-table-tree {
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 20px;
}

.ant-divider-horizontal::before {
  border-top: var(--gray-border) !important;
}

.ant-divider-horizontal::after {
  border-top: var(--gray-border) !important;
}

.ant-card-hoverable:hover {
  border-color: var(--card-border-color);
}

.general__link {
  color: #40a9ff;
  cursor: pointer;
}
.general__link:hover {
  text-decoration: underline;
}
.general__json-as-tree {
  background: none;
  height: 100%;
  overflow-x: auto;
}
.general__feedback-modal .ant-modal-confirm-btns {
  display: none;
}
.general__feedback-modal .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-left: 0px;
}
.general__fitting-modal .ant-modal-content {
  width: min-content !important;
}
.general__card {
  width: 400px;
  overflow: hidden;
  background: #fafafa;
  border: var(--gray-border);
}
.general__card .ant-card-head {
  padding: 0 12px !important;
  background: #f1f1f1 !important;
  border-bottom: var(--gray-border);
}
.general__card .ant-card-head-title {
  padding: 0 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.general__card .ant-card-body {
  padding: 8px 12px !important;
}
.ant-tree .general__card--card-sub-title {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 6px;
}
.general__card--p {
  font-size: 12px;
  margin: 0;
}
.general__tags-column {
  width: 256px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.general__tags-column .ant-tag:hover {
  opacity: 1;
}
.general__code-text-area {
  font-family: monospace;
  border: none;
  background: #f3f3f3;
  height: 100% !important;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none !important;
  resize: none;
}
.general__gray-divider {
  border-top: var(--gray-border) !important;
}
.general__canvas-pointer canvas {
  cursor: pointer !important;
}
.ant-card-bordered {
  border: var(--gray-border) !important;
}

.create-service__general-step {
  width: 400px;
}
.create-service__data-object-step {
  width: 700px;
}
.create-service__edit {
  width: 750px !important;
}
.dashboard__content {
  height: fit-content;
  margin-bottom: 24px;
}
.dashboard__content--center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashboard__grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  height: 157px;
  column-gap: 24px;
  row-gap: 24px;
  align-content: baseline;
}
.dashboard__grid::-webkit-scrollbar {
  display: none;
}
.dashboard__grid--card {
  height: 157px;
  overflow: hidden;
  background: #fcfcfc;
  border: var(--gray-border);
  min-width: 190px;
}
.dashboard__grid--card .ant-card-head {
  padding: 0 12px !important;
  background: #fafafa !important;
  border-bottom: var(--gray-border);
}
.dashboard__grid--card .ant-card-head-title {
  padding: 0 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dashboard__grid--card .ant-card-body {
  padding: 8px 12px !important;
}
.dashboard__grid--service-card {
  height: 157px;
  overflow: hidden;
  background: #fff;
  border: var(--gray-border);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px;
  min-width: 447px;
}
.dashboard__grid--service-card--title {
  white-space: "nowrap";
  overflow: hidden;
  text-overflow: "ellipsis";
  background: #fff;
  padding: 12px 0;
}
.dashboard__grid--service-card .ant-card-head {
  padding: 0 12px !important;
  background: #fff !important;
  border-bottom: var(--gray-border);
}
.dashboard__grid--service-card .ant-card-head-title {
  padding: 0 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dashboard__grid--service-card .ant-card-body {
  padding: 8px 12px !important;
}
.dashboard__grid--longcard {
  height: fit-content;
  background: #fcfcfc;
  border: var(--gray-border);
}
.dashboard__grid--longcard .ant-card-head {
  padding: 0 12px !important;
  background: #fafafa !important;
  border-bottom: var(--gray-border);
}
.dashboard__grid--longcard .ant-card-head-title {
  padding: 0 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dashboard__grid--longcard .ant-card-body {
  padding: 8px 12px !important;
}
.dashboard__grid--graph {
  height: 338px;
  max-width: 530px;
  grid-row: span 2;
  grid-column: span 2;
  overflow: initial;
  background: #fcfcfc;
  border: var(--gray-border);
}
.dashboard__grid--graph .ant-card-head {
  padding: 0 12px !important;
  background: #fafafa !important;
  border-bottom: var(--gray-border);
}
.dashboard__grid--graph .ant-card-head-title {
  padding: 0 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dashboard__grid--graph .ant-card-body {
  padding: 8px 12px !important;
}
.menubar {
  display: grid;
  border-bottom: 1px solid #d4d4d4;
  background-color: #f5f5f5;
  grid-template-columns: minmax(220px, 1fr) minmax(400px, 1470px) minmax(220px, 1fr);
  user-select: none;
}
.menubar__item {
  position: relative;
  top: 1px;
  display: inline-block;
  vertical-align: bottom;
  border-bottom: 2px solid transparent;
  margin: 0;
  padding: 0 20px;
  white-space: nowrap;
  cursor: pointer;
  line-height: 40px;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.menubar__item--selected {
  color: #1890ff;
  border-bottom: 2px solid #1890ff;
}
.menubar__item:hover {
  color: #1890ff;
  border-bottom: 2px solid #1890ff;
}
.menubar__image {
  width: 36px !important;
  height: 38px !important;
  clip-path: circle(16px at center);
  object-fit: cover;
}
.menubar__image-item {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.menubar__image-item > div {
  display: flex !important;
}
.menubar__image-item--name-item {
  pointer-events: none !important;
}
.menubar__left {
  height: var(--height-of-menubar);
  position: absolute;
  display: flex;
  align-items: center;
  margin-left: 12px;
}
.menubar__right {
  height: var(--height-of-menubar);
  position: absolute;
  display: flex;
  align-items: center;
  margin-right: 12px;
  right: 0;
}
.menubar .ant-select-selection-item {
  text-align: end;
}
.sdetail-view__page-content {
  border: 1px solid #d1d1d1;
  background: #fafafa;
  min-width: 400px;
}
.sdetail-view__small-content {
  border: 1px solid #d1d1d1;
  background: #fafafa;
  width: 550px;
}
.sdetail-section__container {
  padding: 8px 16px 8px 16px;
  background: #fff;
  margin-bottom: 16px;
  margin-top: 4px;
  border: 1px solid #e1e1e1;
  white-space: pre-wrap;
}
.sdetail-section__frameless {
  margin-bottom: 16px;
  margin-top: 4px;
  white-space: pre-wrap;
}
.sdetail-section__grid {
  display: grid;
  column-gap: 24px;
  row-gap: 16px;
  margin-bottom: 16px;
}
.sdetail-section__service-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 24px;
  row-gap: 16px;
  margin-bottom: 16px;
}
.sdetail-element__header {
  margin: 0px;
}
.sdetail-element__value {
  margin: 0;
  padding: 4px;
  align-self: center;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  display: block;
}
.sdetail-element__value .ant-select-selector > span {
  width: 0;
}
.sdetail-element__container {
  display: grid;
  grid-template-columns: 90px 1fr;
  column-gap: 4px;
  align-items: center;
  width: 100%;
}
.sdetail-files__list-item {
  align-content: center;
  padding: 10px 0;
}
.sdetail-files__list-item .ant-list-item-meta-title {
  margin: 0;
}
.sdetail-files__list-item .ant-list-item-action {
  display: flex !important;
  align-items: center;
}
.sdetail-files__list-item:hover {
  background-color: #f1f1f1;
}
.sdetail-files__list-item-meta {
  display: flex !important;
  align-items: center;
}
.sdetail-files__list-item-meta .ant-list-item-meta-avatar {
  display: flex !important;
  align-items: center;
}
.sdetail-files__icons {
  font-size: 20px;
  padding-left: 4px;
}
.services__filter-column--column-selection-grid {
  direction: ltr;
  display: grid;
  grid-template-columns: min-content 180px min-content;
  column-gap: 8px;
  margin-left: 8px;
}
.services__filter-column--header {
  margin-top: 12px;
  margin-bottom: 6px;
  grid-column: span 3;
}
.services__filter-column--container {
  direction: rtl;
  max-height: 210px;
  overflow: hidden;
}
.services__filter-column--container:hover {
  overflow: overlay;
}
.services__search-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.services__content {
  height: 522px;
  margin: 24px 0;
}
.services__content--center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.services__grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  height: 100%;
  column-gap: 24px;
  row-gap: 24px;
  align-content: baseline;
}
.services__grid::-webkit-scrollbar {
  display: none;
}
.services__grid--card {
  height: 157px;
  max-width: 335px;
  overflow: hidden;
  background: #fafafa;
  border: var(--gray-border);
}
.services__grid--card .ant-card-head {
  padding: 0 12px !important;
  background: #f1f1f1 !important;
  border-bottom: var(--gray-border);
}
.services__grid--card .ant-card-head-title {
  padding: 0 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.services__grid--card .ant-card-body {
  padding: 8px 12px !important;
}
.services__grid--card-sub-title {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 6px;
}
.services__grid--card-p {
  font-size: 12px;
  margin: 0;
}
.services__type-tag {
  margin: 0;
  font-size: 16px;
  padding: 3px 6px;
  border-radius: 2px;
}
.services__type-tag--large {
  font-size: 24px;
  padding: 7px 9px;
}
.services__json-reader {
  background: "#ffffff" !important;
}
.services__json-reader > span {
  padding: 0px 0 12px !important;
  height: inherit !important;
}
.services__hide-dropdown {
  display: none;
}
.sform_item {
  overflow-x: hidden;
  padding-left: 1px;
}
.sform__section {
  padding: 8px 16px 8px 16px;
  background: #fff;
  margin-bottom: 16px;
  margin-top: 4px;
  border: 1px solid #e1e1e1;
}
.sform__select .ant-select-clear {
  margin-right: 34px;
}
.sform__select--custom-not-found-content {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.65);
}
.sform__select--custom-not-found-content:hover {
  text-decoration: underline;
}
.sform__modal {
  min-width: fit-content !important;
}
.sform__modal .ant-modal-body {
  padding: 0;
}
.side-menu__container {
  opacity: 0.97;
}
.side-menu__filler {
  height: calc(100%) !important;
}
.side-menu__toggle {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.pushable {
  margin: 0 !important;
  border: none !important;
  border-radius: 0 !important;
}
.ui.toggle.checkbox .box:before,
.ui.toggle.checkbox label:before {
  background-color: #555;
}
.ui.toggle.checkbox input:focus ~ .box:before,
.ui.toggle.checkbox input:focus ~ label:before {
  background: #555;
}
.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ label:before,
.ui.toggle.checkbox input:focus:checked ~ .box:before,
.ui.toggle.checkbox input:focus:checked ~ label:before {
  background-color: #2185d0 !important;
}

.super-table .ant-table-selection-column {
  display: none;
}
.super-table .ant-table {
  border-left: var(--ast-border);
}
.super-table .ant-table-thead > tr > th {
  background-color: var(--ast-header-color) !important;
  white-space: nowrap;
  border-top: var(--ast-border) !important;
  user-select: none;
  text-align: left !important;
  padding: 6px 8px !important;
}
.super-table .ant-table-thead > tr > th .ant-table-column-sorters {
  padding: 0 !important;
}
.super-table .ant-table-thead > tr > .super-table__actions-column-cell {
  text-align: center !important;
}
.super-table .ant-table-row:hover > td {
  background: unset !important;
}
.super-table .ant-table-cell {
  border-bottom: var(--ast-border);
  border-right: var(--ast-border);
  white-space: nowrap;
  overflow: hidden;
  padding: 4px 8px !important;
}
.super-table .ant-table-column-sort {
  background: unset;
}
.super-table__selectable .ant-table-row {
  cursor: pointer;
}
.super-table__selectable .ant-table-row:hover > td {
  background: var(--ast-highlight-color) !important;
}
.super-table__selectable .ant-table-row-selected:hover > td {
  background: var(--ast-select-color) !important;
}
.super-table__selectable .ant-table-row-selected > td {
  border-bottom: var(--ast-border) !important;
  border-right: var(--ast-border) !important;
}
.super-table__cell-ellipsis {
  overflow-x: hidden;
  text-overflow: ellipsis;
}
.super-table__cell-collapse {
  width: 1px;
}
.super-table__menubar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;
}
.super-table__menubar--left {
  display: flex;
  align-items: center;
}
.container {
  display: grid;
  grid-template-columns: min-content auto;
  column-gap: 100px;
  margin: 100px;
}
.link-column {
  white-space: nowrap;
}
.information-container a {
  display: none;
}
.teams__information-grid {
  display: grid;
  grid-template-columns: min-content min-content;
  grid-column-gap: 30px;
  grid-row-gap: 12px;
}
.teams__information-grid--item {
  white-space: nowrap;
}
.teams__information--member {
  color: #36c;
  cursor: pointer;
}
.main-container {
  height: calc(100vh - 43);
  padding-top: 10px;

  display: grid;
  grid-template-columns: auto [main-content-start] minmax(400px, 1400px) [main-content-end] auto;

  overflow-y: overlay;
  overflow-x: auto;
}

.main-container__content {
  grid-column: main-content-start;
  padding: 0 20px 200px 20px;
  position: relative;
}
